<template>
  <div id="profile">
    <v-container grid-list-xl fluid>
      <header-title title="Perfil"></header-title>
      <!-- <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>-->
      <v-form ref="form">
        <v-card>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    type="text"
                    label="Nombre completo *"
                    v-model="user.nombres"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    type="text"
                    label="Paterno"
                    v-model="user.primer_apellido"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    type="text"
                    label="Materno"
                    v-model="user.segundo_apellido"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    type="text"
                    label="Cédula identidad *"
                    v-model="user.ci"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-select
                    label="Expedido *"
                    :items="expedidos"
                    v-model="user.expedido"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    type="text"
                    label="Usuario *"
                    v-model="user.usuario"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-switch
                    v-model="user.cambia_clave"
                    :label="
                      user.cambia_clave
                        ? 'Cambiar contraseña? Si'
                        : 'Cambiar contraseña? No'
                    "
                  >
                  </v-switch>
                  <v-text-field
                    type="password"
                    label="Contraseña *"
                    v-model="user.password"
                    :rules="[
                      user.cambia_clave
                        ? ((v) => !!v || 'El campo es requerido',
                          (v) => (!!v && v.length > 4) || 'Mínimo de 5 dígitos',
                          (v) =>
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{5,16}[^'\s]/.test(
                              v
                            ) ||
                            'Debe tener letra mayúscula, minuscula y número')
                        : true,
                    ]"
                    :disabled="!user.cambia_clave"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <p class="text-center">
              <v-btn color="primary" @click.native="updateProfile()">
                <v-icon>mdi-refresh</v-icon>Actualizar
              </v-btn>
            </p>
          </v-card-text>
        </v-card>
      </v-form>
    </v-container>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
      >{{ snack.text }}</v-snackbar
    >
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "users",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      expedidos: [],
      user: {
        id: "",
        nombres: "",
        primer_apellido: "",
        segundo_apellido: "",
        ci: "",
        expedido: "",
        usuario: "",
        password: "",
        cambia_clave: false,
      },
      snack: {
        state: false,
        color: "success",
        mode: "",
        timeout: 2500,
        text: "",
      },
    };
  },
  mounted() {
    if (Service.getUser() != null) {
      this.expedidos = Service.getExpedidos();
      this.getUser(Service.getUser().uid);
    }
  },
  methods: {
    getUser(uid) {
      axios
        .get(Service.getBase() + "usuario/" + uid, Service.getHeader())
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },
    updateProfile() {
      if (this.$refs.form.validate()) {
        axios
          .put(
            Service.getBase() + "usuario/profile/" + this.user.id,
            this.user,
            Service.getHeader()
          )
          .then(() => {
            this.toast("success", "Registro actualizado");
            if (Service.getUser() != null) {
              this.getUser(Service.getUser().uid);
            }
          })
          .catch(() => {
            this.toast("error", "No se pudo actualizar");
            console.error("Error al actualizar");
          });
      }
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
